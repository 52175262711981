<template>
  <div id="merci-header">
    <div class="image-bg">
      <img
        src="@/assets/merci-youpaq-bg.svg"
        alt=""
      >
    </div>
    <div class="content">
      <div class="content-image">
        <img
          class="d-md-none"
          src="@/assets/slider-shop-image-mobile.png"
          alt=""
        >
        <img
          class="d-none d-md-flex"
          src="@/assets/slider-shop-image.png"
          alt=""
        >
      </div>
      <div class="content-text">
        {{ $t('merci.title') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MerciHeader'
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

#merci-header {
  background-color: var(--red-color);
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-height: 480px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 800px;
  }

  .image-bg {
    position: absolute;
    top: 5%;
    left: 0;

    img {
      max-width: 30vw;
    }
  }

  .content {
    background-color: var(--yellow-color);
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px 1px 7px #0000005e;
    position: relative;
    max-width: 330px;

    @include media-breakpoint-up(md) {
      min-height: 250px;
      max-width: 675px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 450px;
      max-width: 1000px;
    }
  }

  .content-text {
    margin-left: 60%;
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    text-align: left;
    padding: 10px 5px 10px 0;
    box-sizing: border-box;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      padding: 20px 20px 20px 0;
    }

    @include media-breakpoint-up(lg) {
      width: 32%;
      margin-left: 50%;
      font-size: 30px;
      padding: 50px 50px 50px 0;
    }
  }

  .content-image {
    width: 190px;
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 2;

    @include media-breakpoint-up(md) {
      width: 395px;
      left: -30px;
      top: -18px;
    }

    @include media-breakpoint-up(lg) {
      width: 720px;
      left: -80px;
      top: -30px;
    }

    img {
      width: 100%;
    }
  }
}
</style>
