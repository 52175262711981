<template>
  <div id="merci-evaluation" class="container-fluid">
    <div class="image-bg">
      <BigText
        color="yellow"
        bgcolor="white"
        :title="$t('merciEvaluation.big')"
        position="left"
      />
    </div>
    <div class="row">
      <div class="col-12 offset-md-1 col-md-10 offset-lg-3 col-lg-6">
        <form v-if="!done" @submit.prevent.stop="submit">
          <h2>{{ $t('merciEvaluation.title') }}</h2>
          <div class="content">
            {{ $t('merciEvaluation.rating') }}
            <MerciEvaluationRating v-model="$v.rating.$model" />
          </div>
          <div class="message">
            <div class="form-group">
              <label for="merciPageMessage">{{ $t('merciEvaluation.message') }}</label>
              <textarea
                id="merciPageMessage"
                v-model="message"
                class="form-textarea form-control"
                rows="10"
              />
            </div>
            <Button
              type="submit"
              class="envoyer-btn"
              :text="$t('merciEvaluation.submit')"
              :loading="loading"
              :disabled="disabled"
            />
          </div>
        </form>
        <div v-else class="message-sent">
          {{ $t('merciEvaluation.sent') }}
        </div>
      </div>
    </div>
    <div class="evaluation-bg d-none d-md-flex" />
  </div>
</template>

<script>
import MerciEvaluationRating from '@/components/CartCheckout/MerciEvaluationRating'
import BigText from '@/components/UI/BigText.vue'
import Button from '@/components/UI/Button.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'MerciEvaluation',
  components: {
    MerciEvaluationRating,
    BigText,
    Button
  },

  data: () => ({
    loading: false,
    done: false,
    rating: null,
    message: null
  }),

  computed: {
    disabled() {
      return this.loading || this.$v.$invalid
    }
  },

  validations: {
    rating: { required }
  },

  methods: {
    submit() {
      if (this.disabled) {
        return
      }
      this.loading = true
      return this.$store.dispatch('cart/feedback', { rating: this.rating, message: this.message })
        .then(() => (this.done = true))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#merci-evaluation {
    font-weight: 900;
    font-size: 20px;
    margin: 80px 0;
    text-align: center;
    position: relative;

    @include media-breakpoint-up(md) {
        margin: 120px 0 120px 0;
    }

    @include media-breakpoint-up(lg) {
        margin: 140px 0;
    }

    .image-bg {
        position: absolute;
        top: -100px;
        left: 25px;

        @include media-breakpoint-up(md) {
          left: 0;
          top: -130px;
        }
        @include media-breakpoint-up(lg) {
            top: -140px;
        }

        h2 {
          background: transparent !important;
          font-size: 28px;
          z-index: -1;

          @include media-breakpoint-up(md) {
            font-size: 65px;
          }
          @include media-breakpoint-up(lg) {
            font-size: 110px;
          }
        }

        img {
            max-width: 70vw;

            @include media-breakpoint-up(lg) {
                max-width: 50vw;
            }
        }
    }

    h2 {
        z-index: 2;
        position: relative;
        font-size: 30px;
        font-weight: 900;
        text-transform: uppercase;
        background: white;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            padding: 10px 20px;
        }
    }
    .content {
        z-index: 4;
        @include media-breakpoint-up(md) {
            padding: 0 150px;
        }
    }
    .message {
        padding: 0 20px;
        margin-top: 50px;
        z-index: 4;

        @include media-breakpoint-up(lg) {
            padding: 0 60px;
            margin-top: 100px;
        }

        .form-group {
            label {
                padding-bottom: 20px;
                @include media-breakpoint-up(lg) {
                    padding-bottom: 40px;
                }
            }
            .form-textarea {
                border: none;
                box-shadow: inset 1px 1px 7px #0000005f;
            }
        }
        .envoyer-btn {
            background-color: var(--red-color);
            border: none;
            color: white;
            text-decoration: none;
            font-size: 18px;
            font-weight: 700;
            padding: 10px 20px;
            border-radius: 15px;
            font-family: inherit;
            box-shadow: 0px 2px 5px #0000005e;
            margin-top: 20px;
        }
    }
    .message-sent {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;

      @include media-breakpoint-up(md) {
        min-height: 200px;
        padding-top: 60px;
      }
    }
}

.evaluation-bg {
  position: absolute;
  top: 20px;
  left: 5%;
  right: 5%;
  bottom: -50px;
  border: 15px solid var(--yellow-color);
  z-index: -1;

  @include media-breakpoint-up(lg) {
    left: 25%;
    right: 25%;
  }
}
</style>
