<template>
  <div>
    <MerciHeader />
    <MerciEvaluation />
    <MerciPartager />
  </div>
</template>

<script>
import MerciHeader from '@/components/CartCheckout/MerciHeader.vue'
import MerciEvaluation from '@/components/CartCheckout/MerciEvaluation.vue'
import MerciPartager from '@/components/CartCheckout/MerciPartager.vue'

export default {
  name: 'ViewMerci',
  components: {
    MerciHeader,
    MerciEvaluation,
    MerciPartager
  },
  props: {
    partnerId: { type: String }
  }
}
</script>
