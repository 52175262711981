<template>
  <div class="heart-icons">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="heart"
      :class="{ active: item.selected }"
    >
      <img
        v-show="item.selected"
        class="full"
        src="@/assets/heart-icon-evaluation.svg"
        @click="reset(item.value)"
      />
      <img
        v-show="!item.selected"
        class="empty"
        src="@/assets/heart-icon-empty-evaluation.svg"
        @click="rating = item.value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerciEvaluationRating',
  props: {
    value: { type: [String, Number] },
    length: { type: [String, Number], default: 5 }
  },
  data: () => ({
    items: []
  }),
  computed: {
    rating: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    rating: 'onRating'
  },
  mounted() {
    for (let i = 0; i < this.length; i += 1) {
      this.items.push({
        value: i + 1,
        selected: false
      })
    }
  },
  methods: {
    onRating(rating) {
      this.items.forEach(item => {
        item.selected = item.value <= rating
      })
    },

    reset(rating) {
      this.items.forEach(item => {
        item.selected = false
      })
      this.rating = rating === this.rating ? null : rating
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.heart-icons {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    img {
        max-width: 40px;
        margin: 0 10px;
        cursor: pointer !important;

        @include media-breakpoint-up(md) {
            max-width: 40px;
        }
    }
}

.heart-icons .heart.active {
    .full {
        display: flex;
    }
    .empty {
        display: none;
    }
}

.heart-icons .heart:not(.active) {
    .full {
        display: none;
    }
    .empty {
        display: flex;
    }
}
</style>
