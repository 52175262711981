<template>
  <div id="merci-partager">
    <div class="image-bg">
      <img
        src="@/assets/partager-plus-bg.svg"
        alt=""
      >
    </div>
    <div class="partager-banner">
      <div class="content">
        <div class="content-image">
          <img
            class="d-md-none mobile-image"
            src="@/assets/merci-partage-mobile.jpg"
            alt=""
          >
          <img
            class="d-none d-md-flex"
            src="@/assets/merci-partage.jpg"
            alt=""
          >
        </div>
        <div class="content-text">
          {{ $t('merciShare.title') }}
        </div>
      </div>
    </div>
    <div class="bottom-text">
      <div class="col-12 offset-md-1 col-md-10 offset-lg-3 col-lg-6 pt-5">
        <p>{{ $t('merciShare.help1') }}</p>
        <p>{{ $t('merciShare.help2') }}</p>
        <template v-if="sponsorCode">
          <p><strong>{{ sponsorCode.code }}</strong></p>
          <p>{{ $t('merciShare.help3', { amount: sponsorCode.amount }) }}</p>
          <p>{{ $t('merciShare.follow') }}</p>
          <div class="cta">
            <a :href="sponsorMail">
              <button type="button" class="btn btn-pink">
                <img src="/imgs/cta-email.png" alt="email" title="email" />
                <br />
                {{ $t('merciShare.ctaEmail') }}
              </button>
            </a>
            <a :href="sponsorWhatsapp">
              <button type="button" class="btn btn-pink">
                <img src="/imgs/cta-whatsapp.png" alt="whatsapp" title="whatsapp" />
                <br />
                {{ $t('merciShare.ctaWhatsapp') }}
              </button>
            </a>
            <a :href="sponsorSms">
              <button type="button" class="btn btn-pink">
                <img src="/imgs/cta-sms.png" alt="sms" title="sms" />
                <br />
                {{ $t('merciShare.ctaSms') }}
              </button>
            </a>
          </div>
        </template>
        <hr />
        <SpamMessage />
      </div>
    </div>
  </div>
</template>

<script>
import SpamMessage from '@/components/MenusAndPopin/SpamMessage'

export default {
  name: 'MerciPartager',
  components: { SpamMessage },
  data: () => ({
    loading: false,
    sponsorCode: null
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    text() {
      return encodeURIComponent(this.$i18n.t('merciShare.ctaText', {
        sponsorCode: this.sponsorCode.code,
        sponsorAmount: this.sponsorCode.amount,
        customerName: this.user.firstname
      }))
    },
    sponsorWhatsapp() {
      return `whatsapp://send?text=${this.text.replace(/%0A%0A/g, '%0A')}`
    },
    sponsorMail() {
      return `mailto:?subject=${this.$i18n.t('merciShare.ctaSubject')}&body=${this.text}`
    },
    sponsorSms() {
      return `sms://;?&body=${this.text.replace(/%0A%0A/g, '%0A')}`
    }
  },
  mounted() {
    this.loading = true
    return this.$store.dispatch('user/getSponsorCode')
      .then(code => (this.sponsorCode = code))
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
#merci-partager {
    background-color: var(--yellow-color);
    padding-bottom: 150px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: 200px;
    }

    .image-bg {
        position: absolute;
        top: 1%;
        left: 20px;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            top: 5%;
        }

        img {
            max-width: 50vw;
        }
    }
}

.partager-banner {
  background-color: var(--yellow-color);
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-height: 480px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 700px;
  }

  .content {
    background-color: var(--red-color);
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px 1px 7px #0000005e;
    position: relative;
    max-width: 330px;

    @include media-breakpoint-up(md) {
      min-height: 250px;
      max-width: 675px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 450px;
      max-width: 1000px;
    }
  }

  .content-text {
    margin-left: 60%;
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    text-align: left;
    padding: 10px 5px 10px 0;
    box-sizing: border-box;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      padding: 20px 20px 20px 0;
    }

    @include media-breakpoint-up(lg) {
      width: 32%;
      margin-left: 50%;
      font-size: 30px;
      padding: 50px 50px 50px 0;
    }
  }
  .content-image {
    width: 190px;
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 3;

    @include media-breakpoint-up(md) {
      width: 395px;
      left: -30px;
      top: -18px;
    }

    @include media-breakpoint-up(lg) {
      width: 720px;
      left: -80px;
      top: -30px;
    }

    img {
      width: 100%;
      transform: rotate(-3deg);
      border: 10px solid white;
    }

    img.mobile-image {
      border-width: 5px;
    }
  }
}

#merci-partager .bottom-text {
   color: white;
   font-size: 20px;
   text-align: center;
   padding: 0 15px;

   strong {
     color: var(--green-color);
     padding: 10px;
     background-color: white;
   }
}

.cta {
  display: flex;
  justify-content: space-around;
  max-width: 300px;
  margin: 0 auto;

  button {
    font-size: 12px;
  }
}
</style>
